<template>
  <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
    <div class="my-team" :style="`top:${isMiniApp ? 0 : '59px'}`">
      <topbar title="推广详情" />
      <!-- <div class="type">
        <span :class="`item ${type === 'team' && 'selected'}`" @click="type = 'team'">团队</span>
        <span :class="`item ${type === 'fans' && 'selected'}`" @click="type = 'fans'">粉丝 </span>
        <span :class="`item ${type === 'normal' && 'selected'}`" @click="type = 'normal'"
          >普通用户
        </span>
      </div>
      <span class="tip" @click="show = true"><span class="query"> ?</span></span> -->

      <div class="search">
        <!-- <div class="total">共计：{{ total }}人</div> -->

        <van-search
          v-model="queryStr"
          placeholder="请输入分销员名称"
          shape="round"
          style="margin-top: 10px; width: 100%"
          :clearable="false"
          @search="getData(1)"
          @blur="getData()"
        ></van-search>
      </div>
      <van-list
        class="list"
        v-model="loading"
        :finished="!isMore"
        finished-text="没有更多了"
        @load="loadMore"
      >
        <div class="item" v-for="(item, index) in list" :key="index">
          <div class="time">
            <div v-if="item.createTime">
              加入时间
              {{ item.createTime | formatFullDate }}
            </div>
            <div v-if="item.lastPurchaseTime" style="margin-top: 5px">
              最近一次购买 {{ item.lastPurchaseTime | formatFullDate }}
            </div>
            <div v-if="item.expireTime" style="margin-top: 5px">
              失效时间： {{ item.expireTime | formatFullDate }}
            </div>
            <div></div>
          </div>

          <div class="user">
            <img class="avatar" :src="item.avatarUrl" />
            <div class="info">
              <div class="top">
                <span class="name"
                  >{{ item.nickname }}
                  <!-- <span v-if="type === 'team'" class="grade">{{ item.distributionLevelName }}级</span> -->
                </span>
                <template v-if="item.teamNum">
                  <div>引流人数{{ item.teamNum }}人</div>
                </template>
              </div>
              <div class="bottom">
                <div>{{ '核销码数' }} ： {{ item.checkCodeCount }}</div>

                <span class="income"
                  >带动CPS佣金
                  <span>{{ item.benefitForParentsTotalIncome || item.totalIncome || 0 }}</span>
                  元</span
                >
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </div>
    <van-popup v-model="show" style="width: 80%; border-radius: 15px" closeable>
      <div class="desc">
        <p class="item">团队：用户注册成为您的下级，用户进入商城购买，永久给您带来收益。</p>

        <p class="item">
          粉丝：用户第一次进入小程序是通过您推广的海报或链接，那他/她会与您产生弱绑定关系。在弱绑定有效期内中，用户进入商城购
          买，都会给您带来收益。
        </p>
        <p class="item">
          普通用户：用户（非分销员）通过您推广的海报或链接购买后产生的订单和收益。用户带动的收益和核销码数均统计已支付的订单（包含未入账和已入账的订单)。
        </p>
        <p class="item">失效时间：弱绑定关系到期时间。</p>
      </div>
      <div style="display: flex; justify-content: center; margin: 30px">
        <van-button round type="danger" @click="show = false">我知道了</van-button>
      </div>
    </van-popup>
  </van-pull-refresh>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      type: 'team',
      total: 0,
      queryStr: '',
      list: [],
      loading: false,
      refreshing: false,
      isMore: true,
      page: 1
    }
  },
  computed: {
    isMiniApp() {
      return this.$store.getters['sys/isBmMiniApp']
    }
  },
  mounted() {
    this.getData()
  },
  watch: {
    type() {
      this.list = []
      this.getData()
    }
  },
  methods: {
    onRefresh() {
      this.refreshing = true
      this.getData()
    },
    loadMore() {
      if (this.isMore) {
        this.getData(this.page + 1)
      }
    },
    getData(page = 1) {
      if (page <= 0) return
      this.loading = true
      // let resA = this.$http.get(
      //   `/boom-center-user-service/app/distributionMember/getWeak?page=${page}&queryStr=${this.queryStr}`
      // )
      // let resB = this.$http.get(
      //   `/boom-center-user-service/app/distributionMember/getTeam?page=${page}&queryStr=${this.queryStr}`
      // )
      this.$http
        .get(
          `/boom-center-user-service/app/distributionMember/countShare?page=${page}&queryStr=${this.queryStr}`
        )
        .then(res => {
          let list = this.list
          if (page === 1) list = []
          this.list = [...list, ...res.list]
          this.page = page
          this.isMore = page * res.size < res.total
          this.total = res.total
        })
        .catch(() => {
          this.isMore = false
        })
        .finally(() => {
          console.log('finally')
          this.loading = false
          this.refreshing = false
        })

      //   this.$http
      //     .get(`${url}`)
      //     .then(res => {
      //       let list = this.list
      //       if (page === 1) list = []
      //       this.list = [...list, ...res.list]
      //       this.page = page
      //       this.isMore = page * res.size < res.total
      //       this.total = res.total
      //     })
      //     .finally(() => {
      //       console.log('finally')
      //       this.loading = false
      //       this.refreshing = false
      //     })
    }
  }
}
</script>

<style lang="less" scope>
.my-team {
  background: #f9f9f9;
  min-height: 100vh;
  .type {
    position: sticky;
    top: 0;
    display: inline-flex;
    padding: 20px 0;
    background: #fff;
    justify-content: space-around;
    font-size: 15px;
    width: 92%;
    .item {
      border-radius: 30px;
      padding: 5px 20px;
      color: #333333;
      background: #fff;
      &:active {
        filter: brightness(95%);
      }
    }
    .selected {
      background: #fde7e8;
      color: #e7141a;
    }
  }
  .tip {
    background: #fff;
    width: 8%;
    display: inline-flex;
    padding: 22px 0;
    .query {
      background: #ec808d;
      color: #fff;
      width: 25px;
      height: 25px;
      text-align: center;
      display: inline-block;
      line-height: 25px;
      border-radius: 50%;
    }
  }
  .count {
    padding: 10px;
    padding-top: 0;
    text-align: center;
    background: #fff;
    color: #999;
  }
  .search {
    display: flex;
    background: #fff;
    align-items: center;
    justify-content: space-between;
    .total {
      margin-left: 20px;
      font-size: 14px;
    }
  }
  .list {
    padding: 15px;
    .item {
      background-color: #fff;
      padding: 15px;
      margin-bottom: 15px;
      border-radius: 5px;
      .time {
        // display: flex;
        // justify-content: space-between;
        font-size: 12px;
        color: #999;
        margin-bottom: 20px;
      }
      .user {
        display: flex;
        .avatar {
          width: 50px;
          height: 50px;
          margin-right: 10px;
          border-radius: 50%;
        }
        .info {
          flex: 1;
          overflow: hidden;
          display: flex;
          justify-content: center;
          flex-direction: column;
          font-size: 12px;
          color: #999;
          .top {
            .name {
              font-weight: bold;
              font-size: 15px;
              color: #555555;
              display: flex;
              align-items: center;
            }
            .grade {
              color: #fff;
              margin: 0 10px;
              display: inline-block;
              text-align: center;
              width: 32px;
              height: 18px;
              line-height: 18px;
              background-color: rgba(236, 128, 141, 1);
              border-radius: 5px;
            }
          }
          .bottom {
            margin-top: 7px;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            .income {
              margin-left: 25px;
              span {
                font-size: 16px;
                color: #e7141a;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }
}
.desc {
  margin: 50px 20px;
  .item {
    font-size: 14px;
    line-height: 22px;
    margin: 20px 0;
  }
}
</style>
